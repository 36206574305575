<template>
  <div :class="backgroundClasses" :id="id" style="position: relative">
    <div class="section-content">
      <v-container fluid class="ma-0 pa-0">
        <v-row>
          <v-col cols="12" sm="8">
            <div class="section-title" v-if="title">{{ title }}</div>
            <div class="section-text" v-html="content" />
          </v-col>
          <v-col cols="12" sm="4">
            <v-img transition="false" cover :src="src" :aspect-ratio="$vuetify.breakpoint.smAndUp ? 0.8 : 1.2" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Section from "./Section";

export default {
  name: "ImagedSection",
  extends: Section,
  props: {
    src: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
