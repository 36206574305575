<template>
  <div class="section content">
    <div class="section-content">
      <div class="section-title">Logga in på din profil</div>
      <form>
        <v-text-field v-model="user" required solo flat light></v-text-field>
        <v-text-field
          v-model="pass"
          type="password"
          required
          solo
          flat
          light
        ></v-text-field>
        <v-btn block elevation="0" class="dark-button mr-4" @click="submit">
          Skicka
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    user: "",
    pass: "",
  }),
  methods: {
    submit() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
