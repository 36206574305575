<template>
  <div class="intro section">
    <div class="section-content">
      <div class="section-title">Våra tjänster</div>
      <div class="section-text">
        <div>Tänk, drygt ett kvarts sekel sedan internet sköts fortfarande många av
          de viktigaste arbetsuppgifterna och projekten via papperslappar. Trots
          en uppsjö administrationssystem och digitala egenkontroller. Därför
          skulle man kunna tro att papperslappar i vår tid vore ett minne blott,
          men icke. Papperslappar hänger sig kvar – och är det som faktiskt styr
          hur ett företag arbetar i grunden med sina viktigaste arbetsuppgifter.
        </div>
        <div class="mt-5">Jag tror jag vet varför. De system som erbjuds är digitala
          skrivbordsprodukter skapade av välmenande utvecklare. Men de är helt
          enkelt för krångliga att lära sig och komma igång och att använda.
          Mycket finesser, men inte riktigt anpassade för vare sig olika
          yrkesroller eller situationer. Insteget blir för stort, så
          papperslapparna blir kvar av vana och trygghet.
        </div>
        <div class="section-text small mt-5">Mattias Rotter, VD iOrdning.org
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroText",
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
