<template>
  <div :class="backgroundClasses" :id="id" style="position: relative">
    <svg
      v-if="background == 'background-3'"
      style="position: absolute; right: 0px; bottom: 0px; height: 30vh"
      viewBox="0 0 100 100"
    >
      <circle cx="100" cy="100" r="80" fill="transparent" opacity="0.4" />
    </svg>
    <svg
      v-else-if="background == 'background-5'"
      style="position: absolute; right: 0px; top: 0px; height: 30vh"
      viewBox="0 0 100 100"
    >
      <circle cx="100" cy="0" r="80" fill="transparent" opacity="0.4" />
    </svg>
    <div class="section-content">
      <div class="section-title" v-if="title">{{ title }}</div>
      <slot name="header"></slot>
      <v-container fluid class="ma-0 pa-0" style="overflow: hidden">
        <slot name="content"></slot>
      </v-container>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Section from "./Section";

export default {
  name: "OneColSection",
  extends: Section,
  props: {},
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
