<template>
  <v-img
    :src="
      $vuetify.breakpoint.smAndUp
        ? require('@/assets/masma47791.jpg')
        : require('@/assets/masma47791-r.jpg')
    "
    position="bottom center"
    class="intro-image"
    transition="false"
  >
    <div class="background background-1" />
    <v-container fill-height fluid pa-8>
      <v-row align="center" justify="center">
        <v-col class="">
          <div class="image-sub-title">Digitala administrationssystem</div>
          <div class="image-title">
            Nyckeln till framgång.<br />Det ska vara enkelt.
          </div>
        </v-col>
      </v-row>
      <div class="bankid-container pa-8">
        <v-container>
          <v-row align="center" justify="center">
            <div class="bankid-text mr-4">
              Vi använder BankID<br />– säkerhet är en del<br />av att komma
              iordning.
            </div>
            <div class="bankid-logo-container">
              <div class="bankid-logo"></div>
            </div>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </v-img>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
