<template>
  <v-app style="background-color: white">
    <v-app-bar
      v-if="$vuetify.breakpoint.smAndUp"
      app
      flat
      height="120"
      color="#393850"
      class="menu-bar"
      ref="appBar"
    >
      <v-img
        :aspect-ratio="249 / 1244"
        class="logo ma-0 ml-6"
        alt="Logo"
        contain
        transition="false"
        height="40px"
        max-width="200px"
        src="@/assets/logo_inv.png"
        @click.stop="scrollToTop"
      />
      <v-spacer />
      <template v-for="(link, i) in menu">
        <div
          :key="i"
          @click.stop="linkTo(link.to)"
          :class="{ 'menu-item': true, current: currentId == link.to }"
        >
          {{ link.title }}
        </div>
      </template>
      <v-btn
        class="outline-button mr-4 ml-8"
        text
        @click.stop="showTry"
      >
        <span>PROVA</span>
      </v-btn>
      <v-btn class="login-button mr-6" text @click.stop="showLoginScreen">
        <span>LOGGA IN</span>
      </v-btn>
    </v-app-bar>

    <v-app-bar
      v-else
      app
      flat
      height="84"
      color="#393850"
      class="menu-bar"
      ref="appBar"
    >
      <v-app-bar-nav-icon @click="showDrawer = !showDrawer"
        ><v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <v-img
        :aspect-ratio="249 / 1244"
        class="logo ma-0"
        alt="iOrdning.org"
        contain
        transition="false"
        height="60px"
        max-width="200px"
        src="@/assets/logo_inv.png"
        @click.stop="scrollToTop"
      />
      <v-spacer />
      <v-btn
        class="outline-button mr-2 small"
        text
        @click.stop="showTry"
      >
        <span>PROVA</span>
      </v-btn>
      <v-btn class="login-button small" text @click.stop="showLoginScreen">
        <span class="mr-2">LOGGA IN</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      fixed
      class="drawer"
      v-if="$vuetify.breakpoint.mobile"
      v-model="showDrawer"
      :style="
        $vuetify.breakpoint.mobile
          ? `margin-top: ${$vuetify.application.top}px`
          : 'padding-top: 10px'
      "
      color="#393850"
      :temporary="$vuetify.breakpoint.mobile"
      :permanent="!$vuetify.breakpoint.mobile"
    >
      <v-list nav dense dark active-class="drawer-item">
        <template v-for="(link, i) in menu">
          <v-list-item
            v-if="!link.subLinks"
            :key="i"
            @click.stop="linkTo(link.to)"
          >
            <v-list-item-title class="drawer-item">{{
              link.title
            }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            :value="true"
            active-class="drawer-item"
          >
            <template v-slot:activator>
              <v-list-item-title class="drawer-item">{{
                link.title
              }}</v-list-item-title>
            </template>
            <v-list-item
              @click.stop="linkTo(sublink.to)"
              class="ml-8"
              v-for="sublink in link.subLinks"
              :key="sublink.title"
            >
              <v-list-item-title class="drawer-item">{{
                sublink.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="!error">
      <v-fade-transition>
        <v-overlay absolute opacity="0.8" v-if="showLogin" class="login">
          <div v-click-outside="hideLogin">
            <v-btn color="black" absolute right @click="showLogin = false" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <Login v-on:close="hideLogin" />
          </div>
        </v-overlay>
      </v-fade-transition>

      <div class="content" v-scroll="onScroll" ref="content" id="content">
        <Intro />
        <IntroText
          id="services"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '0' : ''"
        />
        <Section
          title="Egenkontroll"
          id="egenkontroller"
          content_file="egenkontroller.txt"
          background="background-2"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '1' : ''"
        />
        <ThreeColSection
          title="Fördelar för alla"
          id="advantages"
          background="background-3"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '0' : ''"
        >
          <template slot="col1">
            <v-img
              background-color="grey"
              transition="false"
              :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1 : 1.5"
              cover
              :src="require('@/assets/fordelar-r.jpg')"
            />
            <div class="section-subtitle">Användaren</div>
            <div
              class="section-text"
              v-html="
                require('!!raw-loader!@/assets/content/adv_part1.txt').default
              "
            />
          </template>
          <template slot="col2">
            <v-img  
             transition="false"
              background-color="grey"
              :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1 : 1.5"
              cover
              :src="require('@/assets/christin-hume-Hcfwew744z4-unsplash-r.jpg')"
            />
            <div class="section-subtitle">Projektledaren</div>
            <div
              class="section-text"
              v-html="
                require('!!raw-loader!@/assets/content/adv_part2.txt').default
              "
            />
          </template>
          <template slot="col3">
            <v-img
              background-color="grey"
              transition="false"
              :aspect-ratio="$vuetify.breakpoint.smAndUp ? 1 : 1.5"
              cover
              :src="require('@/assets/shutterstock_84049690-r.jpg')"
            />
            <div class="section-subtitle">Företaget</div>
            <div
              class="section-text"
              v-html="
                require('!!raw-loader!@/assets/content/adv_part3.txt').default
              "
            />
          </template>
        </ThreeColSection>

        <!-- Priser -->
        <OneColSection title="Priser" id="prices" background="background-5">
          <template slot="header">
            <div class="section-text mb-8" style="white-space: normal">
              Vi vill även att våra priser är enkla att förstå, så därför har vi
              delat in dom i tre olika grupper, beroende på hur många användare
              ni är.
            </div>
          </template>
          <template slot="content">
            <table class="price-table">
              <tr>
                <td>
                  <div class="price-title text-center">1-24 användare</div>
                </td>
                <td>
                  <div class="price-title text-center">25-49 användare</div>
                </td>
                <td>
                  <div class="price-title text-center">50+ användare</div>
                </td>
              </tr>
              <tr>
                <td class="price-cell back1">
                  <div class="price-price text-center">599 KR</div>
                  <div class="price-info text-center">
                    för 1 tjänst per användare/månad
                  </div>
                </td>
                <td class="price-cell back2">
                  <div class="price-price text-center">549 KR</div>
                  <div class="price-info text-center">
                    för 1 tjänst per användare/månad
                  </div>
                </td>
                <td class="price-cell back3">
                  <div class="price-price text-center">499 KR</div>
                  <div class="price-info text-center">
                    för 1 tjänst per användare/månad
                  </div>
                </td>
              </tr>
              <tr>
                <td class="price-cell2 back4">
                  <div class="price-info text-center">
                    + 59 kr per tilläggstjänst och användare/månad
                  </div>
                </td>
                <td class="price-cell2 back5">
                  <div class="price-info text-center">
                    + 55 kr per tilläggstjänst och användare/månad
                  </div>
                </td>
                <td class="price-cell2 back6">
                  <div class="price-info text-center">
                    + 49 kr per tilläggstjänst och användare/månad
                  </div>
                </td>
              </tr>
            </table>
          </template>
          <template slot="footer">
            <div class="price-footer mt-8">
              Du får kostnadsfritt prova tjänsten i upp till 3 månader för upp
              till 10 användare.
            </div>
            <div class="text-center">
              <v-btn
                class="dark-button"
                style="margin-top: 50px; min-width: 40%"
                text
                @click.stop="showTry"
              >
                <span>JAG VILL PROVA</span>
              </v-btn>
            </div>
          </template>
        </OneColSection>

        <ImagedSection
          title="Om oss"
          id="om_oss"
          content_file="om_oss.txt"
          background="background-2"
          :src="require('@/assets/mattias-small.jpg')"
          :data-size-class="$vuetify.breakpoint.smAndUp ? '1' : ''"
        />
        <Contact id="kontakta_oss" background="background-4" />
        <Footer background="background-6" />
      </div>

      <!-- Cookie information -->
      <cookie-law
        theme="iordning"
        buttonText="okej"
        buttonLinkText="Mer information"
        buttonLink="./cookies.html"
        buttonLinkNewTab
      >
        <div slot="message">
          <b>Möte med kaka?</b><br />Vill du veta mer om administrationssystem
          och hur du äntligen kommer iordning, sätt på kaffet så kommer vi förbi
          med kaka! Kakor använder vi även på vår hemsida för att du ska få en
          bättre upplevelse, analysera trafiken och använda för digital
          marknadsföring.
        </div>
      </cookie-law>
    </v-main>
    <v-main v-else>
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
        <Error />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Intro from "./components/Intro.vue";
import IntroText from "./components/IntroText";
import Section from "./components/Section.vue";
import ImagedSection from "./components/ImagedSection";
import OneColSection from "./components/OneColSection";
import ThreeColSection from "./components/ThreeColSection";
import Login from "./components/Login.vue";
import Contact from "./components/Contact.vue";
import CookieLaw from "vue-cookie-law";
import Footer from "./components/Footer";
import Error from "./components/Error.vue";

export default {
  name: "App",

  components: {
    Intro,
    IntroText,
    Section,
    OneColSection,
    ThreeColSection,
    Login,
    Contact,
    CookieLaw,
    ImagedSection,
    Footer,
    Error
  },
  props: {
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data: () => ({
    showDrawer: false,
    showLogin: false,
    currentId: null,
    currentIdx: null,
    scrollDisabled: false,
    menu: [
      {
        title: "Våra tjänster",
        to: "services",
      },
      {
        title: "Priser",
        to: "prices",
      },
      {
        title: "Om oss",
        to: "om_oss",
      },
      {
        title: "Kontakta oss",
        to: "kontakta_oss",
      },
    ],
  }),

  mounted() {
    this.recalcSizeClasses();
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    showLoginScreen() {
      this.$gtag.pageview({page_title: "login", page_location: "https://apps.iordning.org"});
      window.location.href = "https://apps.iordning.org";
    },

    showTry() {
      this.$gtag.pageview({page_title: "prova", page_location: "https://apps.iordning.org/createtenant"});
      window.location.href = "https://apps.iordning.org/createtenant";
    },

    onResize() {
      // Remove old size contraints
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        child.style.minHeight = 0;
      }
      this.$nextTick(() => {
        this.recalcSizeClasses();
      });
    },

    /**
     * To make elements "same height as x" we use HTML atrtibutes to set "data-size-class" to a class value. Then we look at ALL
     * the content child elements that have the same class value and set a minimum size to be the largest of the elements.
     */
    recalcSizeClasses() {
      var sizeClasses = {};

      // Gather size classes from elements. Group them by class.
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        const attr = child.getAttribute("data-size-class");
        if (attr && attr.length > 0) {
          if (!sizeClasses[attr]) {
            sizeClasses[attr] = [];
          }
          sizeClasses[attr].push(child);
        }
      }

      // Process them
      for (const sizeClass of Object.keys(sizeClasses)) {
        const elements = sizeClasses[sizeClass];
        if (!elements || elements.length < 2) {
          // If less than 2 elements we have nothing to compare against...
          continue;
        }
        var maxHeight = 0;
        for (var el of elements) {
          const r = el.getBoundingClientRect();
          if (r.height > maxHeight) {
            maxHeight = r.height;
          }
        }
        // Second pass, set min height data
        for (el of elements) {
          el.style.minHeight = "" + maxHeight + "px";
        }
      }
    },

    linkTo(id) {
      this.showDrawer = false;
      if (id) {
        // If error page, link to "Main"
        if (this.error) {
          window.location.href = "./#" + id;
          return;
        }

        // Log pageview
        this.$gtag.pageview({page_title: id});

        this.scrollDisabled = true;
        setTimeout(() => {
          this.scrollDisabled = false;
        }, 1500);
        this.$vuetify.goTo("#" + id);
        this.currentId = id;
        var idx = null;
        for (var i = 0; i < this.$refs.content.children.length; i++) {
          const child = this.$refs.content.children[i];
          if (child.id == id) {
            idx = i;
            break;
          }
        }
        this.currentIdx = idx;
      }
    },
    scrollToTop() {
      if (this.error) {
        window.location.href = "./";
        return;
      }
      this.scrollDisabled = true;
      setTimeout(() => {
        this.scrollDisabled = false;
        this.onScroll();
      }, 1500);
      this.$vuetify.goTo("#content");
    },
    hideLogin() {
      this.showLogin = false;
    },
    onScroll() {
      if (this.scrollDisabled) {
        return;
      }
      const rect = this.$refs.appBar.$el.getBoundingClientRect();
      for (var i = 0; i < this.$refs.content.children.length; i++) {
        const child = this.$refs.content.children[i];
        if (!child.id) {
          continue;
        }
        const r = child.getBoundingClientRect();
        if (this.currentIdx != null && i < this.currentIdx) {
          // Scrolling up. Top must cross top in that case!
          if (r.top >= rect.bottom && r.bottom > rect.bottom) {
            this.currentId = child.id;
            this.currentIdx = i;
            return;
          }
        } else if (r.top <= rect.bottom && r.bottom > rect.bottom) {
          if ((r.bottom + r.top) / 2 >= rect.bottom) {
            // More than half is hidden
            if (i + 1 < this.$refs.content.children.length) {
              const c = this.$refs.content.children[i + 1];
              if (c.id) {
                this.currentId = c.id;
                this.currentIdx = i + 1;
                return;
              }
            }
          } else {
            this.currentId = child.id;
            this.currentIdx = i;
            return;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
