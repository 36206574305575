

<template>
  <div :class="backgroundClasses" style="padding-top: 70px;padding-bottom: 30px" :id="id">
    <div class="section-content">
      <v-container fluid class="ma-0 pa-0 text-center">
        <img width="150px" contain src="@/assets/logo_inv.png" />
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
    background: {
      type: String,
      default: function () {
        return null;
      },
    },
  },

  data: () => ({
    name: "",
    company: "",
    email: "",
    message: "",
  }),

  computed: {
    backgroundClasses() {
      var clazz = { section: true };
      if (this.background) {
        clazz[this.background] = true;
      }
      return clazz;
    },
  },

  methods: {
    submit() {},
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
